<template>
  <tile>
    <template #heading>
      <div class="d-flex justify-between mb-5">
        <div class="d-flex align-center">
          <span class="header-areas semibold">
            {{ GraficsName[heading] }}
          </span>
          <tooltip>
            <svg-icon
              class="info__icon ml-1 mt-1"
              name="info-fill"
            />
            <template #popover>
              <span v-if="heading == 'orders_money'">Сумма заказов в рублях: Wildberries с учетом комиссии маркетплейса, Ozon - без учета комиссии</span>
              <span v-else>Топ 5 товаров по заказам в рублях</span>
            </template>
          </tooltip>
        </div>
      </div>
    </template>
    <div
      v-if="loadind"
      class="dashboard__loader"
    >
      <loader />
    </div>
    <list
      v-else
      :points="top_5"
    />
  </tile>
</template>

<script>
import Tile from '@/components/Common/Tile.vue'
import Loader from '@/components/Common/Loader.vue'
import Tooltip from '@/components/Common/Tooltip.vue'
import List from '@/components/Interface/List.vue'
import GraficsName from '@/constants/dashboards_grafics_name'
import '@/styles/grafics.styl'

export default {

  components: {
    List,
    Tile,
    Loader,
    Tooltip
  },

  props: {
    heading: {
      type: String,
      default: ''
    },
    measure: {
      type: String,
      default: ''
    },
    points: {
      type: Object,
      required: true
    },
    showDay: {
      type: Boolean,
      default: false
    },
    showWeek: {
      type: Boolean,
      default: false
    },
    loadind: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      GraficsName,
      top_5: []
    }
  },

  watch: {
    points: {
      handler (value) {
        this.top_5 = []
        value.product_name.forEach((item, index) => {
          const result = {
            id: value.product_id[index],
            name: item,
            money: Number(value.orders_money[index]),
            percent: Math.round(Number(value.orders_money_diff[index]))
          }
          this.top_5.push(result)
        })
      },
      deep: true
    }
  },

  mounted () {
    this.updateGrafic()
  },

  created () {
    window.addEventListener('resize', this.updateGrafic)
  },

  methods: {
    updateGrafic () {
      const widthWindow = window.innerWidth
      if (widthWindow >= 1440) {
        this.chartOptions.chart.height = '100px'
      } else if (widthWindow >= 1366) {
        this.chartOptions.chart.height = '100px'
      } else if (widthWindow >= 1280) {
        this.chartOptions.chart.height = '100px'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .header-areas
    margin-right 4px

  .info__icon
    opacity 0.5

  .last-date
    display inline-block
    background-color #FFF8C7
    border-radius 14px
    padding 0 6px
    font-size 12px
    font-weight normal
    margin-left 4px
    white-space nowrap

  .semibold
    font-weight 600
</style>
