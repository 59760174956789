<template>
  <ol>
    <li
      v-for="(point, index) in points"
      :key="point.id"
      class="list__item"
    >
      <span>{{ index + 1 }}. {{ point.name }}</span>
      <div class="list__item-data">
        <p class="list__item-data__money">
          {{ `${formatPrice(point.money, { maximumFractionDigits: 0 })}` }}  ₽
        </p>
        <p :class="point.percent > 0 ? 'positive' : 'negative'">
          {{ point.percent }}%
        </p>
      </div>
    </li>
  </ol>
</template>

<script>
import formatPrice from '@/utils/price-formatter'

export default {
  props: {
    points: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      formatPrice
    }
  }
}
</script>

<style lang="stylus" scoped>
.list__item
  display flex
  justify-content space-between
  align-items center
  gap 10px
  margin-left 20px
  padding 4px 8px
  font-size 14px

  .positive
    color #09C89F

  .negative
    color #F2453D

.list__item:nth-child(odd)
  background-color #F7F7F8

.list__item-data
  text-align right

.list__item-data__money
  white-space nowrap
</style>
