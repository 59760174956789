<template>
  <tile>
    <template #heading>
      <div class="d-flex justify-between">
        <div class="d-flex align-center">
          <span class="header-areas semibold">
            {{ GraficsName[heading] }}
          </span>
          <tooltip>
            <svg-icon
              class="info__icon ml-1 mt-1"
              name="info-fill"
            />
            <template #popover>
              <span v-if="heading == 'products_in_selling'">Динамика товаров в продаже, товары у которых карточка доступна и есть положительный остаток</span>
              <span v-else-if="heading == 'products_with_zero_stock'">Количество товаров у которых отсутствует остаток на складе продавца или маркетплейса</span>
              <span v-else-if="heading == 'products_with_errors'">Для Wildberries это количество карточек товара в статусе “черновик”, для Ozon - раздел “Товары и цены” - “С ошибками”</span>
              <span v-else-if="heading == 'expired_feedbacks_count'">Количество отзывов, на которые не был дан ответ продавца более 24 часов с момента появления отзыва</span>
            </template>
          </tooltip>
          <span
            v-if="!legend"
            class="last-date"
          >
            {{ lastDay }}
          </span>
        </div>
      </div>
    </template>
    <div
      v-if="loadind"
      class="dashboard__loader"
    >
      <loader />
    </div>
    <chart
      v-else
      :options="chartOptions"
    />
  </tile>
</template>

<script>
import { Chart } from 'highcharts-vue'
import Tile from '@/components/Common/Tile.vue'
import Loader from '@/components/Common/Loader.vue'
import Tooltip from '@/components/Common/Tooltip.vue'
import Measures from '@/constants/measure-name'
import GraficsName from '@/constants/dashboards_grafics_name'
import formatterTooltip from '@/utils/formatterTooltip'
import formatterLegend from '@/utils/formatterLegend'
import formatterLabel from '@/utils/formatterLabel'
import formatterData from '@/utils/formatterDataLabel'
import creater from '@/utils/creater-data'
import '@/styles/grafics.styl'

export default {

  components: {
    Chart,
    Tile,
    Loader,
    Tooltip
  },

  props: {
    heading: {
      type: String,
      default: ''
    },
    measure: {
      type: String,
      default: ''
    },
    points: {
      type: Object,
      required: true
    },
    showDay: {
      type: Boolean,
      default: false
    },
    showWeek: {
      type: Boolean,
      default: false
    },
    loadind: {
      type: Boolean,
      default: false
    },
    isShowInfo: {
      type: Boolean,
      default: false
    },
    legend: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      Measures,
      GraficsName,
      lastDay: '',
      zeroData: false,
      chartOptions: {
        credits: {
          enabled: false
        },
        chart: {
          type: 'line',
          style: {
            fontFamily: 'proxima_nova',
            fontWeight: 'normal'
          },
          backgroundColor: 'transparent'
        },
        title: {
          text: null
        },
        xAxis: {
          categories: [],
          lineColor: '#E5E5E9',
          title: {
            useHTML: true,
            style: {
              color: '#7B7B85',
              padding: '0 50px',
              backgroundColor: '#FFFDE8'
            }
          },
          labels: {
            formatter () {
              return formatterLabel.simpleLabel(this.isFirst, this.isLast, this.value)
            },
            reserveSpace: true,
            style: {
              color: '#7b7b85',
              whiteSpace: 'nowrap',
              textOverflow: 'none'
            },
            rotation: 0
          }
        },
        yAxis: {
          title: {
            text: null
          },
          min: 0,
          labels: {
            format: this.measure === Measures.PERCENT ? '{value}%' : '',
            style: {
              color: '#7b7b85',
              fontSize: 10
            }
          }
        },
        plotOptions: {
          line: {
            marker: {
              symbol: 'circle'
            },
            dataLabels: {
              enabled: false,
              backgroundColor: 'rgba(21, 21, 34, 0.7)',
              formatter () {
                return formatterData.formatSimpleData(this)
              },
              color: '#FFFFFF',
              padding: 4,
              align: 'right',
              verticalAlign: 'middle',
              borderRadius: '4px',
              useHTML: true,
              x: -15
            }
          },
          series: {
            marker: {
              enabledThreshold: 100
            }
          }
        },
        tooltip: {
          enabled: true,
          crosshairs: true,
          shared: true,
          shadow: false,
          borderWidth: 0,
          backgroundColor: 'transparent',
          style: {
            zIndex: 999
          },
          useHTML: true,
          headerFormat: formatterTooltip.formatterHeader(this.showDay, this.showWeek),
          pointFormatter: formatterTooltip.formatterMultipleTooltip
        },
        colors: ['#1EABF1', '#F2453D', '#8DC252', '#F7A738', '#A926FB'],
        legend: {
          enabled: false,
          alignColumns: false,
          layout: this.legend ? 'horizontal' : 'vertical',
          align: 'center',
          width: this.legend ? '100%' : undefined,
          margin: 28,
          symbolPadding: 0,
          symbolWidth: 0,
          symbolHeight: 0,
          squareSymbol: false,
          useHTML: !this.legend,
          labelFormatter: this.legend ? formatterLegend.simpleLegend : formatterLegend.formatLegend
        },
        series: []
      }
    }
  },

  watch: {
    showDay (value) {
      const showBy = {
        show_day: value,
        show_week: this.showWeek
      }
      this.chartOptions.series = this.points.values.map(point => creater.createStoresStatisticSeries(showBy, point, this.measure, this.heading, this.legend))
      this.chartOptions.tooltip.headerFormat = formatterTooltip.formatterHeader(this.showDay, this.showWeek)
    },
    showWeek (value) {
      const showBy = {
        show_day: this.showDay,
        show_week: value
      }
      this.chartOptions.series = this.points.values.map(point => creater.createStoresStatisticSeries(showBy, point, this.measure, this.heading, this.legend))
      this.chartOptions.tooltip.headerFormat = formatterTooltip.formatterHeader(this.showDay, this.showWeek)
    },
    points: {
      handler (value) {
        this.zeroData = false
        this.updateGrafic()
        const arr = value.values ? value.values : []
        const result = new Set()
        arr.forEach(_ => {
          const lastNotNull = _.data.findLastIndex(data => data !== null)
          if (lastNotNull !== -1) {
            result.add(value.dates[lastNotNull])
          }
        })
        if (!result.size) {
          this.lastDay = ''
        } else if (result.size > 1 && result.has(value.dates[value.dates.length - 1])) {
          this.lastDay = `за ${value.dates[value.dates.length - 1]}`
        } else {
          this.lastDay = `за ${Array.from(result)[0]}`
        }
        this.chartOptions.xAxis.categories = value.dates ? value.dates : []
        this.chartOptions.series = value.values ? value.values.map(point => {
          point.data.forEach(_ => {
            if (_ !== 0 && _ !== null) {
              this.zeroData = true
            }
          })
          return creater.createStoresStatisticSeries({
            show_day: this.showDay,
            show_week: this.showWeek
          }, point, this.measure, this.heading, this.legend)
        }) : []
        this.chartOptions.yAxis.max = this.zeroData ? undefined : 1
        this.chartOptions.yAxis.min = this.zeroData ? undefined : 0
      },
      deep: true
    }
  },

  mounted () {
    this.updateGrafic()
  },

  created () {
    window.addEventListener('resize', this.updateGrafic)
  },

  methods: {
    updateGrafic () {
      const widthWindow = window.innerWidth
      if (widthWindow >= 1440) {
        this.chartOptions.legend.align = this.legend ? 'center' : 'left'
        this.chartOptions.legend.verticalAlign = this.legend ? 'bottom' : 'top'
        this.chartOptions.legend.width = this.legend ? '100%' : '312px'
        this.chartOptions.chart.height = this.points.values && this.points.values.length > 1 ? this.points.values.length * 110 : 150
      } else if (widthWindow >= 1366) {
        this.chartOptions.legend.align = 'center'
        this.chartOptions.legend.verticalAlign = 'bottom'
        this.chartOptions.legend.width = this.legend ? '100%' : '340px'
        this.chartOptions.chart.height = this.points.values && this.points.values.length > 1 ? 400 : 200
      } else if (widthWindow >= 1280) {
        this.chartOptions.legend.align = 'center'
        this.chartOptions.legend.verticalAlign = 'bottom'
        this.chartOptions.legend.width = this.legend ? '100%' : '312px'
        this.chartOptions.chart.height = this.points.values && this.points.values.length > 1 ? 400 : 200
      }
      if (this.legend) {
        this.chartOptions.chart.height = 200
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .info__icon
    opacity 0.5

  .last-date
    display inline-block
    background-color #FFF8C7
    border-radius 14px
    padding 0 6px
    font-size 12px
    font-weight normal
    margin-left 4px
    white-space nowrap

  .semibold
    font-weight 600
</style>
